import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { SecondaryPagesHero, Seo } from "../../components/elements"

const TransferPatient = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const page = data.datoCmsCancerMedicalProf
  return (
    <Layout>
      <Seo
        title="Transfer a Patient"
        description="WVU Medical Command (MedCom) at J.W. Ruby Memorial Hospital serves as a regional coordination and communication center for all pre-hospital emergency care, including aircraft, such as the HealthNet helicopter, which brings patients to a rooftop helipad."
      />

      <Main>
        <SecondaryPagesHero page={page} title="Transfer a Patient">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel="transfer a patient"
          />
        </SecondaryPagesHero>

        {/* IntroContent */}
        <Section id="intro">
          <Container>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", "full", 3 / 4]}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.transferAPatientNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default TransferPatient

export const query = graphql`
  {
    datoCmsCancerMedicalProf {
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      transferAPatientNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
